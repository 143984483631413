<template>
  <section>
    <div class="mb-2">
      <b-row
        v-if="showAddItem"
        class="mb-1"
      >
        <b-col class="d-flex justify-content-end">
          <e-button
            :text="$t('Adicionar item')"
            variant="primary"
            @click="onAddItem"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <FormulateForm ref="formulateInvoiceItems">
            <b-table
              ref="productTable"
              show-empty
              striped
              class="bordered"
              :fields="fields"
              :items="items"
              style="max-height: 500px !important"
            >
              <template #cell(erpSku_name)="{ item, index }">
                <div
                  v-if="!localReadOnly && (!item.skuId || !item.sku)"
                  style="display: flex; margin-right: 5px"
                >
                  <div style="width: 100%">
                    <FormulateInput
                      :id="`invoice-items-sku-searched-${index}`"
                      v-model="item.skuId"
                      type="select-searchable"
                      :placeholder="$t('Digite para pesquisar')"
                      :options="skusOptions"
                      autofocus
                      :instruction="$t('Digite pelo menos 3 numeros ou letras para iniciar a pesquisa')
                      "
                      style="width: 100%; min-width: 200px; display: inline-block"
                      class="ml-50"
                      validation="required"
                      @fetchSearch="fetchSearchSku"
                      @input="selectSku(item, item.skuId)"
                    />
                  </div>
                </div>
                <div
                  v-else
                  style="display: flex"
                >
                  <div style="display: flex; align-items: center; margin: 0 10px">
                    <e-button
                      icon="journal-text"
                      variant="primary"
                      class="p-5"
                      :title="$t('Dados fiscais do produto')"
                      @click="onShowItemDataModal(item)"
                    />
                  </div>
                  <e-product-router-link
                    :sku-id="item.sku.id"
                    :product-id="item.sku.productId"
                    :product-name="item.sku.name"
                    :ean="item.sku.ean"
                  />
                </div>
              </template>

              <template #cell(erpSku_ean)="row">
                <span>{{ row.item.sku ? row.item.sku.ean : '' }}</span>
              </template>

              <template #cell(erpSku_unitOfMeasurement)="row">
                <span>{{
                  row.item.sku
                    ? row.item.sku.unitOfMeasurement || row.item.unit || '-'
                    : row.item.unit || '-'
                }}</span>
              </template>

              <template #cell(cfop)="row">
                <FormulateInput
                  v-if="canEditCfop"
                  :id="`invoice-items-cfop-${row.index}`"
                  v-model="row.item.cfop"
                  type="vue-select"
                  :options="cfopOptionsByNatureOperation"
                  :title="$t('Clique para editar')"
                  validation="required"
                  :clearable="false"
                  @input="onUpdateItem"
                />
                <span v-else>{{ row.item.cfop }}</span>
              </template>

              <template
                v-if="isStockLossesCategory"
                #cell(stockLossesReason)="row"
              >
                <FormulateInput
                  v-if="!localReadOnly"
                  :id="`invoice-items-loss-reason-${row.index}`"
                  v-model="row.item.stockLossesReason"
                  type="vue-select"
                  :options="invoiceStockLossesReasonOptions()"
                  :class="{ required: isStockLossesCategory }"
                  :title="$t('Clique para editar')"
                  :validation="isStockLossesCategory ? 'required' : ''"
                  :clearable="false"
                  @input="onUpdateItem"
                />
                <span v-else>{{ invoiceStockLossesReasonLabel[row.item.stockLossesReason] }}</span>
              </template>

              <template #cell(erpSku_quantity)="row">
                <FormulateInput
                  v-if="canEditErpSkuQuantity"
                  :id="`invoice-items-quantity-${row.index}`"
                  v-model="row.item.quantity"
                  type="text-number"
                  :precision="3"
                  class="text-right"
                  :title="$t('Clique para editar')"
                  validation="required|min:1"
                  :validation-messages="{
                    required: $t('Obrigatório'),
                    min: $t('Deve ser maior que 1'),
                  }"
                  @blur="onUpdateItem"
                />
                <span v-else>{{ row.item.quantity | number(3) }}</span>
              </template>

              <template #cell(erpSku_unitValue)="row">
                <template v-if="isXml">
                  {{ (row.item.totalValue / row.item.quantity) | currency }}
                </template>
                <template v-else>
                  <FormulateInput
                    v-if="canEditUnitPrice"
                    :id="`invoice-items-unit_price-${row.index}`"
                    v-model="row.item.unityPrice"
                    type="text-number"
                    currency="R$"
                    :precision="3"
                    class="text-right"
                    :title="$t('Clique para editar')"
                    validation="required|min:0.01"
                    :validation-messages="{
                      required: $t('Obrigatório'),
                      min: $t('Deve ser maior que 0.01'),
                    }"
                    style="width: 120px"
                    @blur="onUpdateItem"
                  />
                  <span v-else>
                    {{ row.item.unityPrice | currency }}
                  </span>
                </template>
              </template>

              <template #cell(aditionalInformation)="row">
                <FormulateInput
                  v-if="!localReadOnly && !isInvoiceComplementary"
                  :id="`invoice-items-additional_information-${row.index}`"
                  v-model="row.item.aditionalInformation"
                  type="textarea"
                  :title="$t('Informação adicional')"
                  style="width: 120px"
                />
                <span v-else>
                  {{ row.item.aditionalInformation || '-' }}
                </span>
              </template>

              <template #cell(fiscal)="row">
                <b>{{ $t('NCM') }}:</b> {{ row.item.ncm }}<br>
                <b>{{ $t('CEST') }}:</b> {{ row.item.cest }}<br>
                <b>{{ $t('CST ICMS') }}:</b> {{ row.item.stIcms }}<br>
                <b>{{ $t('CST PIS') }}:</b> {{ row.item.stPis }}<br>
                <b>{{ $t('CST COFINS') }}:</b> {{ row.item.stCofins }}<br>
                <e-button
                  v-if="!isInvoiceComplementary"
                  style="color: blue"
                  class="p-0"
                  variant="link"
                  :text="$t(!row.detailsShowing ? 'Ver mais' : 'Recolher')"
                  @click="row.toggleDetails"
                >{{
                  row.detailsShowing ? 'Hide' : 'Show' }}</e-button>
              </template>

              <template #row-details="row">
                <div style="margin-left: 80px">
                  <b-row class="mb-1">
                    <b-col md="2">
                      <FormulateInput
                        :id="`icms-base-${row.index}`"
                        v-model="row.item.icmsBase"
                        :label="$t('Base de ICMS')"
                        type="text-number"
                        :disabled="!isInvoiceComplementary || localReadOnly"
                        :precision="2"
                        @blur="onUpdateItem"
                      />
                    </b-col>
                    <b-col md="2">
                      <FormulateInput
                        :id="`icms-percent-${row.index}`"
                        v-model="row.item.icmsPercent"
                        :label="$t('Alíquota de ICMS')"
                        type="text-percetage"
                        currency="%"
                        :disabled="!isInvoiceComplementary || localReadOnly"
                        :precision="4"
                        @blur="onUpdateItem"
                      />
                    </b-col>
                    <b-col md="2">
                      <FormulateInput
                        :id="`icms-value-${row.index}`"
                        v-model="row.item.icmsValue"
                        :label="$t('Valor de ICMS')"
                        type="text-number"
                        :disabled="!isInvoiceComplementary || localReadOnly"
                        :precision="2"
                        @blur="onUpdateItem"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="2">
                      <FormulateInput
                        :id="`icms-st-base-${row.index}`"
                        v-model="row.item.icmsStBase"
                        :label="$t('Base de ICMS ST')"
                        type="text-number"
                        :disabled="!isInvoiceComplementary || localReadOnly"
                        :precision="2"
                        @blur="onUpdateItem"
                      />
                    </b-col>
                    <b-col md="2">
                      <FormulateInput
                        :id="`icms-st-percent-${row.index}`"
                        v-model="row.item.icmsStPercent"
                        :label="$t('Alíquota de ICMS ST')"
                        type="text-percetage"
                        :disabled="!isInvoiceComplementary || localReadOnly"
                        currency="%"
                        :precision="4"
                        @blur="onUpdateItem"
                      />
                    </b-col>
                    <b-col md="2">
                      <FormulateInput
                        :id="`icms-st-iva-${row.index}`"
                        v-model="row.item.iva"
                        :label="$t('IVA')"
                        type="text-percetage"
                        :disabled="!isInvoiceComplementary || localReadOnly"
                        currency="%"
                        :precision="4"
                        @blur="onUpdateItem"
                      />
                    </b-col>
                    <b-col md="2">
                      <FormulateInput
                        :id="`icms-st-value-${row.index}`"
                        v-model="row.item.icmsStValue"
                        :label="$t('Valor de ICMS ST')"
                        type="text-number"
                        :disabled="!isInvoiceComplementary || localReadOnly"
                        :precision="2"
                        @blur="onUpdateItem"
                      />
                    </b-col>
                  </b-row>
                </div>
              </template>

              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="false"
                  :show-delete="showRemoveItem"
                  @delete="onRemoveItem(row.item)"
                />
              </template>
            </b-table>
          </FormulateForm>
        </b-col>
      </b-row>
    </div>

    <invoice-item-data-sidebar
      ref="invoiceItemDataSidebar"
      :is-read-only="localReadOnly"
      @update-item-tax="onUpdateItemTax"
    />
  </section>
</template>

<script>
import { BRow, BCol, BTable } from 'bootstrap-vue'
import { EButton, EProductRouterLink, EGridActions } from '@/views/components'
import { invoiceDomains, alerts } from '@/mixins'
import { mapGetters, mapState } from 'vuex'
import InvoiceItemDataSidebar from './InvoiceItemDataSidebar.vue'

export default {
  name: 'ItemsFormTable',

  components: {
    BRow,
    BCol,
    BTable,
    EButton,
    EGridActions,
    InvoiceItemDataSidebar,
    EProductRouterLink,
  },

  mixins: [alerts, invoiceDomains],

  props: {
    isXml: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isReturn: {
      type: Boolean,
      default: false,
    },
    isOutput: {
      type: Boolean,
      default: true,
    },
    isInvoiceComplementary: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      saving: false,
      productOptions: [],
      updateCellIndex: null,
      sku: null,
      skus: [],
    }
  },

  computed: {
    ...mapState('pages/invoice/invoice/invoiceMaintain', ['natureOperationCfop']),
    ...mapGetters('pages/invoice/taxClassifications', [
      'getComboAllCfop',
      'getComboCfopOutput',
      'getComboCfopInput',
    ]),
    ...mapGetters('pages/invoice/invoice/invoiceMaintain', [
      'isGeneratingInReadOnlyMode',
      'isInvoiceFromCouponCategory',
      'isInvoiceReturnFromCouponCategory',
      'isStockLossesCategory',
    ]),

    localReadOnly() {
      return this.isReadOnly || this.isGeneratingInReadOnlyMode
    },
    skusOptions() {
      return this.skus.map(sku => ({
        ...sku,
        value: sku.id,
        label: sku.name,
      }))
    },

    isInvoiceFromInventory() {
      return !!this.invoice.stockInventory?.id
    },

    cfopOptionsByNatureOperation() {
      const selectedNatureCfop = this.natureOperationCfop
      const optionsAux = this.isOutput ? this.getComboCfopOutput : this.getComboCfopInput
      if (this.isReadOnly) return optionsAux

      if (!selectedNatureCfop?.code) return optionsAux

      const hasInNatureCfopRelated = cfop =>
        selectedNatureCfop.cfopRelatedCfops.some(rel => rel.cfopRelatedCode === cfop.code)
      const optionsAuxFiltered = optionsAux.filter(hasInNatureCfopRelated)

      return optionsAuxFiltered.length > 0 ? optionsAuxFiltered : optionsAux
    },

    showRemoveItem() {
      return !this.isReturn && !this.isInvoiceFromInventory
    },

    showAddItem() {
      return !(
        this.isXml ||
        this.localReadOnly ||
        this.isReturn ||
        this.isInvoiceReturnFromCouponCategory ||
        this.isInvoiceFromInventory
      )
    },

    canEditCfop() {
      return (
        !this.localReadOnly &&
        !this.isInvoiceComplementary &&
        !this.isInvoiceReturnFromCouponCategory
      )
    },
    canEditErpSkuQuantity() {
      return (
        !this.localReadOnly &&
        !this.isReturn &&
        !this.isInvoiceComplementary &&
        !this.isInvoiceReturnFromCouponCategory &&
        !this.isInvoiceFromInventory
      )
    },
    canEditUnitPrice() {
      return (
        !this.localReadOnly &&
        !this.isReturn &&
        !this.isInvoiceComplementary &&
        !this.isInvoiceReturnFromCouponCategory
      )
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '80px' },
          hide: this.isXml || this.localReadOnly || this.isReturn || this.isInvoiceFromInventory,
        },
        {
          label: this.$t('Produto'),
          key: 'xmlProduct.name',
          hide: !this.isXml,
        },
        {
          label: this.$t('Unid.'),
          key: 'xmlProduct.unitOfMeasurement',
          class: 'text-center',
          hide: !this.isXml,
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          class: 'text-center',
          formatter: value => this.$options.filters.number(value, 3, true),
          hide: !this.isXml,
        },

        {
          label: this.$t('Produto (Cód. Barras)'),
          key: 'erpSku_name',
          thClass: 'echope-product',
        },
        {
          label: this.$t('Cód Barras'),
          key: 'erpSku_ean',
          thClass: 'echope-product',
          thStyle: { width: '80px' },
          hide: true,
        },
        {
          label: this.$t('Unid.'),
          key: 'erpSku_unitOfMeasurement',
          class: 'text-center',
          thClass: 'echope-product',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Fiscal'),
          key: 'fiscal',
        },

        {
          label: this.$t('Ncm'),
          key: 'ncm',
          thClass: 'text-center',
          thStyle: { width: '100px' },
          hide: true,
        },
        {
          label: this.$t('Cest'),
          key: 'cest',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          hide: true,
        },
        {
          label: this.$t('Cfop'),
          key: 'cfop',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Qtd.'),
          key: 'erpSku_quantity',
          class: 'text-center',
          thClass: 'echope-product',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.number(value, 3, true),
        },
        {
          label: this.$t('Motivo baixa'),
          key: 'stockLossesReason',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '140px' },
          hide: !this.isStockLossesCategory,
        },
        {
          label: this.$t('Informação Adicional'),
          key: 'aditionalInformation',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '110px' },
        },
        {
          label: this.$t('Vlr. Unit.'),
          key: 'erpSku_unitValue',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Vlr. Total'),
          key: 'totalValue',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.currency(value),
        },
        {
          label: this.$t('Cst ICMS'),
          key: 'stIcms',
          thClass: 'text-center',
          tdClass: 'text-cemter',
          thStyle: { width: '80px' },
          hide: true,
        },
        {
          label: this.$t('Cst PIS'),
          key: 'stPis',
          thClass: 'text-center',
          tdClass: 'text-cemter',
          thStyle: { width: '80px' },
          hide: true,
        },
        {
          label: this.$t('Cst COFINS'),
          key: 'stCofins',
          thClass: 'text-center',
          tdClass: 'text-cemter',
          thStyle: { width: '80px' },
          hide: true,
        },
      ].filter(a => !a.hide)
    },
    items() {
      return this.invoice?.items.filter(i => !i.isDeleted)
    },
  },

  mounted() { },

  methods: {
    createNewProduct(productItem, index) {
      this.$router.push({
        name: 'purchase-order-xml-import-add-product',
        params: {
          callbackRouteName: this.$route.name,
          purchaseOrderData: {
            invoice: this.invoice,
            itemIndex: index,
          },
        },
      })
    },

    async selectSku(item, skuIdSelected) {
      if (skuIdSelected) {
        const localItem = { ...item }
        localItem.sku = this.skus.find(sku => sku.id === parseInt(skuIdSelected, 10))

        this.$emit('update-product-item', localItem)
      }
    },

    async deleteSkuAssociation(item) {
      if (
        await this.confirm({
          icon: 'question',
          text: this.$t('Tem certeza que deseja remover esta associação?'),
        })
      ) {
        // eslint-disable-next-line no-param-reassign
        item.skuId = null
        // eslint-disable-next-line no-param-reassign
        item.sku = null
      }
    },

    async fetchSearchSku(loading, search) {
      try {
        loading(true)
        this.skus = (
          await this.$http.get('/api/skus/search', {
            params: {
              search,
              active: true,
            },
          })
        ).data.results
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },

    onShowItemDataModal(item) {
      this.$refs.invoiceItemDataSidebar.show(item)
    },

    onAddItem() {
      if (this.invoice.storeId === null) {
        this.showWarning({
          title: this.$t('Loja não selecionada'),
          message: this.$t('Selecione a loja para adicionar os itens.'),
        })
        return
      }

      if (!this.natureOperationCfop) {
        this.showWarning({
          title: this.$t('Natureza da operação'),
          message: this.$t('Selecione a natureza da operação para adicionar os itens.'),
        })
        return
      }

      if (this.items.some(i => !i.skuId)) {
        this.showWarning({
          title: this.$t('Item sem produto'),
          message: this.$t('Preencha os campos de produto antes de adicionar um item.'),
        })
        return
      }

      this.$emit('add-item')
    },
    onUpdateItem() {
      this.$emit('update-invoice-item-totals')
    },
    onUpdateItemTax(item) {
      this.$emit('update-item', item)
    },
    async onRemoveItem(item) {
      const confirmed = await this.confirm({
        icon: 'question',
        text: this.$t('Tem certeza que deseja remover este item?'),
      })

      if (confirmed) {
        this.$emit('remove-item', item)
      }
    },

    validate() {
      this.$refs.formulateInvoiceItems.showErrors()
      const productMissing = this.items.some(i => !i.skuId)
      return this.$refs.formulateInvoiceItems.hasErrors || productMissing
    },
  },
}
</script>

<style>
.echope-product {
  background-color: #e7e7e7 !important;
}
</style>
